/* global */

$(document).foundation();
/* eslint-disable */
$.isInViewport = (el) => {
  const elementTop = $(el).offset().top + 500;
  const elementBottom = elementTop + $(el).outerHeight();
  const viewportTop = $(window).scrollTop();
  const viewportBottom = viewportTop + $(window).height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(() => {
  $.slidebars();
});

/* global AOS */
$(window).on('load', () => {
  AOS.init();
});

$(document).ready(function () {
  $('.blog-slider').owlCarousel({
    center: true,
    items: 3,
    loop: true,
    margin: 10,
    nav: true,
    navText: [
      "<span><img src='/wp-content/themes/tourlabs-mspqi/assets/icons/icon-blog-prev.png'></span>",
      "<span><img src='/wp-content/themes/tourlabs-mspqi/assets/icons/icon-blog-next.png'></span>"
    ],
    dots: false,
    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1
      },
      // breakpoint from 480 up
      640: {
        items: 2,
        center: false,
      },
      // breakpoint from 768 up
      1024: {
        items: 3
      }
    }
  });

  /* eslint-disable */
  StickyHeader();
  $(window).scroll(function (event) {
    StickyHeader();
  });

  $('.profile-accordion-title').click(function () {
    $(this).toggleClass('opened');
    $(this).parent().siblings('.profile-accordion-content').slideToggle();
  });

  $('.circle-btn').click(function () {
    var to = $(this).attr('data-to');
    console.log(to);
    $('html,body').animate({
        scrollTop: $(`#${to}`).offset().top - 100
      },
      1000
    );
  });

  $('.hamburger').addClass('action action--open').attr('aria-label', 'Open Menu');

  var menuEl = document.getElementById('ml-menu'),
    mlmenu = new MLMenu(menuEl, {
      breadcrumbsCtrl: false, // show breadcrumbs
      // initialBreadcrumb : 'all', // initial breadcrumb text
      backCtrl: true // show back button
    });

  // mobile menu toggle
  var openMenuCtrl = document.querySelector('.action--open'),
    closeMenuCtrl = document.querySelector('.action--close'),
    sliderOverlay = document.querySelector('.slider-menu-overlay');

  closeMenuCtrl.addEventListener('click', closeMenu);
  openMenuCtrl.addEventListener('click', openMenu);

  function openMenu() {
    classie.add(menuEl, 'menu--open');
    closeMenuCtrl.focus();
    $('.slider-menu-overlay-wrap').css('height', 'calc(100%)');
    $('.slider-menu-overlay').fadeIn();
    $('.slider-menu-btns').css({
      top: $('.menu__level[data-menu="main"]').height() + 'px'
    });
    $('#menu-slidebars-menu > li.current-menu-parent > a').addClass('active').addClass('open');

    $('#menu-slidebars-menu > li.current-menu-parent').find('.sub-menu').css('display', 'block');
    $('.hamburger').addClass('is-active');
  }

  function closeMenu() {
    classie.remove(menuEl, 'menu--open');
    openMenuCtrl.focus();

    $('.slider-menu-overlay-wrap').css('height', '0');
    $('.slider-menu-overlay').fadeOut();
    $('.hamburger').removeClass('is-active');
  }

  sliderOverlay.onclick = function () {
    closeMenuCtrl.click();
  };

  $('.show-more-btn').click(function () {
    if ($(window).width() < 640) {
      $('.row-image-column .cell:hidden').slice(0, 4).slideDown().css('display', 'block');
    } else {
      $('.row-image-column .cell:hidden').slice(0, 4).slideDown().css('display', 'flex');
    }

  });

  $('#menu-main-menu > .menu-item')
    .mouseenter(function () {
      $(this).children('.sub-menu').stop().slideDown();
    })
    .mouseleave(function () {
      $(this).children('.sub-menu').stop().slideUp();
    });

  $('#menu-main-menu > .menu-item > .sub-menu .menu-item-has-children')
    .mouseenter(function () {
      $(this).children('.sub-menu').stop().slideDown();
    })
    .mouseleave(function () {
      $(this).children('.sub-menu').stop().slideUp();
    });
  // slidebar menu

  $('#menu-slidebars-menu .menu-item-has-children > a').each(function () {
    $(this).append('<span></span>');
  });

  $('.no-link > a').click(function (e) {
    e.preventDefault();

  });


  $('.slidebars-menu .menu-item-has-children > a span').click(function (e) {

    e.preventDefault();

    $(this).parent().toggleClass('active');
    $(this).parent().siblings('.sub-menu').slideToggle();


  });
});

const StickyHeader = () => {
  var scroll = $(window).scrollTop();
  // Do something
  if (scroll > 100) {
    $('.header-wrap').addClass('fixed-header-nav');
  } else {
    $('.header-wrap').removeClass('fixed-header-nav');
  }
};
